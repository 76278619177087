"use strict";

(function () {
  class BatchImportFindingCtrl {
    constructor($scope, $http, Finding, Site, $stateParams, $uibModalInstance) {
      this.$http = $http;
      this.$scope = $scope;
      this.Finding = Finding;
      this.objectdata = [];
      this.displayProgress = "";
      this.validationError = "";
      this.fileName = "";
      this.Site = Site;
      this.$stateParams = $stateParams;
      this.site = 0;
      this.$uibModalInstance = $uibModalInstance;
      self = this;
      this.isDisabledForStartImport = true;
      this.isDisabled = true;
      this.ExcelSheetType = "";
      this.imageUrl = true;
      this.message = "";
      this.messageDisplay = false;
    }

    handleFileSelect(event) {
      this.isDisabledForStartImport = false;
      this.isDisabled = false;
      var resultArray = [];
      const id = this.$stateParams.id;
      const siteId = id;
      const file = event.target.files;
      this.fileName = file[0].name;

      const reader = new FileReader();
      if (this.ExcelSheetType === "Sulzer") {
        reader.onload = (event) => {
          this.isDisabled = false;
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });

          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const headerRow = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            range: 6, // Starting from the 7th row (index 6)
          })[0];

          const validRows = [
            "Windpark",
            "Turbine type",
            "Turbine",
            "Inspection date [DMY]",
            "Blade index",
            "Blade serial",
            "Severity",
            "Annotation type",
            "Root cause",
            "Annotation size [cm]",
            "Blade side",
            "Radius, centered [m]",
            "Radius, initial [m]",
            "Radius, final [m]",
            "Width [cm]",
            "Height [cm]",
            "Repair priority",
            "Annotation link (for registered users)",
            "Annotation link (for guest users)",
            "Annotation ID",
            "Original file link",
          ];

          for (let i = 0; i < validRows.length; i++) {
            const columnName = validRows[i];
            if (!headerRow.includes(columnName)) {
              this.validationError = `Invalid file format. ${columnName} is missing.`;
              this.isDisabled = true;
              this.$scope.$apply();
            }
          }

          const cells = Object.keys(worksheet);

          const linksColumnIndex = Object.keys(worksheet).findIndex(
            (key) =>
              worksheet[key].v === "Annotation link (for registered users)"
          );
          cells.forEach((cell) => {
            if (linksColumnIndex !== -1 && cell.startsWith("R")) {
              const col = cell.substring(0, 1);
              if (
                col === "R" &&
                worksheet[cell].l &&
                worksheet[cell].l.Target
              ) {
                worksheet[cell].v = new URL(worksheet[cell].l.Target).href;
              }
            }
          });
          const linksColumnIndex2 = Object.keys(worksheet).findIndex(
            (key) => worksheet[key].v === "Annotation link (for guest users)"
          );
          cells.forEach((cell) => {
            if (linksColumnIndex2 !== -1 && cell.startsWith("S")) {
              const col = cell.substring(0, 1);
              if (
                col === "S" &&
                worksheet[cell].l &&
                worksheet[cell].l.Target
              ) {
                worksheet[cell].v = new URL(worksheet[cell].l.Target).href;
              }
            }
          });

          // Skip the first 6 rows to reach the row with headers
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          range.s.r = 6; // Set the start row to 7th row (index 6)
          worksheet["!ref"] = XLSX.utils.encode_range(range);

          // Convert worksheet data to JSON array
          const result = XLSX.utils.sheet_to_json(worksheet, {
            blankrows: false,
          });

          // Filter out rows that contain only 0 values
          const filteredResult = result.filter((row) =>
            Object.values(row).some((cell) => cell !== 0)
          );

          let annotationId;
          const modifiedArray = filteredResult.map((obj) => {
            const entries = Object.entries(obj);
            const modifiedEntries = entries.map(([key, value]) => {
              // Modify the key name as needed
              if (key === "Blade side") {
                return ["bladeSide", value];
              } else if (key === "Blade index") {
                return ["blade", value];
              } else if (key === "Turbine") {
                return ["turbine", value];
              } else if (key === "Inspection date [DMY]") {
                const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
                const milliseconds = (value % 1) * millisecondsPerDay;
                const days = Math.floor(value);
                const date = new Date(1900, 0, days, 0, 0, 0, milliseconds);

                const formattedDate = date.toISOString();

                return ["date", formattedDate.toString()];
              } else if (key === "Annotation type") {
                return ["findingType", value];
              } else if (key === "Radius, final [m]") {
                return ["distanceFromRoot", parseFloat(value.toFixed(2))];
              } else if (key === "Annotation size [cm]") {
                return [
                  "defectLengthSpan",
                  parseFloat((value * 10).toFixed(2)),
                ];
              } else if (key === "Material") {
                return ["Annotation Type (Coat damage)", value];
              } else if (key === "Annotation ID") {
                annotationId = value;
                return ["externalDefectId", value];
              } else if (key === "Annotation link (for guest users)") {
                return ["image", value];
              } else if (key === "Annotation link (for registered users)") {
                return ["externalLink", value];
              } else if (key === "Inspection Type") {
                return ["inspectionType", "EXTERNAL"];
              } else if (key === "Severity") {
                return ["category", value];
              } else if (key === "Annotation size [cm]") {
                return ["siteId", ""];
              } else if (key === "Height [cm]") {
                return ["siteId", ""];
              } else if (key === "Original file link") {
                return ["siteId", ""];
              } else if (key === "Radius, centered [m]") {
                return ["siteId", ""];
              } else if (key === "Radius, final [m]") {
                return ["siteId", ""];
              } else if (key === "Radius, initial [m]") {
                return ["siteId", ""];
              } else if (key === "Root cause") {
                return ["siteId", ""];
              } else if (key === "Turbine type") {
                return ["siteId", ""];
              } else if (key === "Width [cm]") {
                return ["siteId", ""];
              } else if (key === "Windpark") {
                return ["siteId", ""];
              } else {
                return [key, value];
              }
            });

            modifiedEntries.push(
              ["siteId", siteId],
              ["inspectionType", "EXTERNAL"]
            );
            return Object.fromEntries(modifiedEntries);
          });

          resultArray.push(...modifiedArray);

          if (resultArray) {
            const promiseArray = resultArray.map((finding) => {
              const id = finding.image.split("/").pop().split("?")[0];
              const imageData = `https://api.3dxportal.com/api/v1/share/${id}`;

              return new Promise((resolve, reject) => {
                this.isDisabled = true;

                this.message =
                  "Fetching defect image URLs from Sulzer-Schmid server, please start import after few seconds.";
                this.messageDisplay = true;
                this.$scope.$apply();

                this.$http
                  .get(imageData)
                  .then(function (response) {
                    const data = response.data;

                    const url = data.fullInspectionInfo.defects.map(
                      (defect) => {
                        if (defect.defectId === finding.externalDefectId) {
                          data.fullInspectionInfo.missions.map((mission) => {
                            if (defect.missionId === mission.missionId) {
                              mission.paths.map((path) => {
                                if (path.bladeView === defect.bladeSection) {
                                  defect.defectImages.map((defectImage) => {
                                    path.images.map((image) => {
                                      if (
                                        image.missionImageId ===
                                        defectImage.missionImageId
                                      ) {
                                        finding.image = image.blobOriginalUrl;
                                      }
                                    });
                                  });
                                }
                              });
                            }
                          });
                        }
                      }
                    );
                  })
                  .then(() => {
                    resolve(finding);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }).catch(function (error) {
                console.error(error);
                reject(error);
              });
            });

            Promise.all(promiseArray)
              .then((modifiedResults) => {
                this.isDisabled = false;
                this.messageDisplay = false;
                this.$scope.$apply();

                this.handleResultArray(modifiedResults);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        };
      }

      if (this.ExcelSheetType === "Skyspecs") {
        reader.onload = (event) => {
          this.isDisabled = false;
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });

          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const headerRow = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          })[0];
          const validRows = [
            "ID",
            "Site",
            "Turbine",
            "Asset",
            "Serial Number",
            "Date",
            "Severity",
            "Component",
            "Material",
            "Type",
            "Subtype",
            "Blade Side",
            "Distance",
            "Length",
            "Width",
            "Coordinates",
            "Source Image Reference",
            "Links",
            "Source Images",
            "Tasks",
          ];
          for (let i = 0; i < validRows.length; i++) {
            const columnName = validRows[i];
            if (!headerRow.includes(columnName)) {
              this.validationError = `Invalid file format. ${columnName} is missing.`;
              this.isDisabled = true;
              this.$scope.$apply();
            }
          }

          const cells = Object.keys(worksheet);

          const linksColumnIndex = Object.keys(worksheet).findIndex(
            (key) => worksheet[key].v === "Links"
          );
          cells.forEach((cell) => {
            if (linksColumnIndex !== -1 && cell.startsWith("S")) {
              const col = cell.substring(0, 1);
              if (
                col === "S" &&
                worksheet[cell].l &&
                worksheet[cell].l.Target
              ) {
                worksheet[cell].v = new URL(worksheet[cell].l.Target).href;
              }
            }
          });
          const linksColumnIndex2 = Object.keys(worksheet).findIndex(
            (key) => worksheet[key].v === "Source Images"
          );
          cells.forEach((cell) => {
            if (linksColumnIndex2 !== -1 && cell.startsWith("T")) {
              const col = cell.substring(0, 1);
              if (
                col === "T" &&
                worksheet[cell].l &&
                worksheet[cell].l.Target
              ) {
                worksheet[cell].v = new URL(worksheet[cell].l.Target).href;
              }
            }
          });
          const result = XLSX.utils
            .sheet_to_json(worksheet, { blankrows: false })
            .filter((row) => Object.values(row).some((cell) => cell !== 0));

          const modifiedArray = result.map((obj) => {
            const entries = Object.entries(obj);
            const modifiedEntries = entries.map(([key, value]) => {
              // Modify the key name as needed
              if (key === "Blade Side") {
                return ["bladeSide", value];
              } else if (key === "Asset") {
                const match1 = value.match(/(blade\ *)(.)/i)[2];
                return ["blade", match1[0]];
              } else if (key === "Turbine") {
                return ["turbine", value];
              } else if (key === "Date") {
                return ["date", value];
              } else if (key === "Type") {
                return ["findingType", value];
              } else if (key === "Distance") {
                return ["distanceFromRoot", value];
              } else if (key === "Length") {
                return ["defectLengthSpan", value * 1000];
              } else if (key === "Width") {
                return ["defectWidthChord", value * 1000];
              } else if (key === "Coordinates") {
                return ["coordinates", value];
              } else if (key === "Defect Depth Thickness (mm)") {
                if (value === "N/A") {
                  return ["defectDepthThickness", 0];
                } else {
                  return ["defectDepthThickness", value];
                }
              } else if (key === "Material") {
                return ["extentOfDamage", value];
              } else if (key === "Chord Distance") {
                return ["chordDistance", value];
              } else if (key === "Source Images") {
                return ["image", value];
              } else if (key === "Links") {
                return ["externalLink", value];
              } else if (key === "Inspection Type") {
                return ["inspectionType", "EXTERNAL"];
              } else if (key === "Severity") {
                return ["category", value];
              } else if (key === "ID") {
                return ["externalDefectId", value];
              } else if (key === "Site") {
                return ["siteId", ""];
              } else if (key === "Source Image Reference") {
                return ["siteId", ""];
              } else if (key === "Component") {
                return ["siteId", ""];
              } else if (key === "Serial Number") {
                return ["siteId", ""];
              } else if (key === "Subtype") {
                return ["siteId", ""];
              } else if (key === "Blade") {
                return ["siteId", ""];
              } else if (key === "Tasks") {
                return ["siteId", ""];
              } else if (key === "Type") {
                return ["type", value];
              } else {
                return [key, value];
              }
            });

            modifiedEntries.push(
              ["siteId", siteId],
              ["inspectionType", "EXTERNAL"]
            );
            return Object.fromEntries(modifiedEntries);
          });
          resultArray.push(...modifiedArray);
          this.handleResultArray(resultArray);
        };
      }
      reader.readAsBinaryString(file[0]);
    }

    handleResultArray(resultArray) {
      this.objectdata = resultArray;
    }

    startImport() {
      let progressCount = 1;
      this.isDisabledForStartImport = true;
      console.log(this.objectdata, "ggjhjjhg");
      async.mapSeries(
        this.objectdata,
        (finding, callback) => {
          self.displayProgress = `Processing ${progressCount} of ${this.objectdata.length}`;
          progressCount = progressCount + 1;

          this.Finding.importFindings(finding).$promise.then((finding) => {
            callback(null, finding);
          });
        },
        function (err, results) {
          if (err) {
            console.log(err);
          }
          self.$uibModalInstance.close();
        }
      );
    }
  }
  angular
    .module("windmanagerApp")
    .controller("BatchImportFindingCtrl", BatchImportFindingCtrl);
})();
